<template>
    <div class="clinic">
      <!-- 表单 -->
      <el-form>
        <el-form-item>
          <el-input
            placeholder="请输入内容"
            v-model="input3"
            class="input-with-select"
          >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-select v-model="form.sex" placeholder="请选择">
            <el-option label="男" value="男"></el-option>
            <el-option label="女" value="女"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年龄区间">
          <el-date-picker
            v-model="form.patienceAgeMin"
            type="datetime"
            style="width: 114px;"
          >
          </el-date-picker>
          <el-date-picker
            v-model="form.patienceAgeMax"
            type="datetime"
            style="width: 114px;"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="诊断查询">
          <el-select v-model="form.query" placeholder="请选择">
            <el-option label="诊断1" value="healthy"></el-option>
            <el-option label="诊断2" value="illness"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary">搜索</el-button>
          <el-button type="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <!-- 按钮 -->
      <div class="button">
        <el-button type="primary"  @click="handleIncluded()">病例纳入</el-button>
        <el-button type="primary">病例排除</el-button>
        <el-button type="primary">导出</el-button>
      </div>
      <!-- 列表 -->
      <el-table
        ref="multipleTable"
        :data="tabData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="姓名" width="220" prop="patience_name"> </el-table-column>
        <el-table-column prop="patience_sex" label="性别" width="220"> </el-table-column>
        <el-table-column prop="age" label="年龄" width="220"> </el-table-column>
        <el-table-column prop="patience_link_tel" label="联系方式" width="220">
        </el-table-column>
        <el-table-column prop="diagnose" label="诊断" width="220">
        </el-table-column>
        <el-table-column prop="visitInfoTime" label="最近访视日期" width="220">
        </el-table-column>
        <el-table-column prop="payload" label="操作" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- <case-table :tableData="tableData" :columns="columns"></case-table> -->
      <!-- Pagination 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[100, 200, 300, 400]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="400"
      >
      </el-pagination>
      <CaseIncluded :state.sync="dialogIncluded"></CaseIncluded>
    </div>
  </template>
  
  <script>
  // import { getTeamsPatienceListApi } from "@/api/case";
  export default {
    components:{
      CaseIncluded:()=>import("./CasesIncluded.vue")
    },
    data() {
      return {
        dialogIncluded:false,
        currentPage4: 5,
        input3: "",
        form: {
          patience_name:"this.patience_name",
          sex: "",
          query: "",
          data1: "",
          data2: ""
        },
        tableData: [],
        pageNo: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false, //列表loading
        tabData:[],//备用列表，在调用数据时使用
        dataList: [], //列表数据
        oldList:[],
        dateTime: ['',''],
      };
    },
    mounted() {
      this.getDataList();
    },
    methods: {
      async handleIncluded() {
      this.dialogIncluded = true;
    },
      // async getDataList() {
      //   const res = await getTeamsPatienceListApi(this.params);
      //   console.log(res)
      // },
      // getDataList() {
      //   console.log(11111111);
      //   let that = this;
      //   let enertyId = "";
        // if (localStorage.currentTeam) {
        //   let token = localStorage.getItem("token");
        //   console.log(token)
        //   enertyId = JSON.parse(localStorage.currentTeam).entityId;
        // }
        // let token = localStorage.getItem("token");
        // let teamsId = localStorage.getItem("currentTeam").id;
        // let patienceName = JSON.parse(localStorage.getItem("userInfo")).name
        // let patienceSex = JSON.parse(localStorage.getItem("userInfo")).sex
        // let params = {
        //   token:token,
        //   pageSize: that.pageSize,
        //   pageNo: that.pageNo,
        //   patienceName:patienceName,
        //   type: '2 病例',
        //   teamsId:teamsId,
        //   patienceSex: patienceSex+'',
          // patienceAge: that.age,
          // startTime: that.dateTime[0],
          // endTime: that.dateTime[1]
        // };
  
      //   that.dataListLoading = true;
      //   that
      //     .$http({
      //       url: that.$http.adornUrl("/teamsPatience/selectTeamsPatience"),
      //       method: "get",
      //       params: params
      //     })
      //     .then(({ data }) => {
      //       console.log('data',data);
      //       if (data.status) {
      //         // data.data.forEach(v=>{
      //         // })
      //         that.tabData = data.data;
      //         if (
      //           that.sortRule.order !== null &&
      //           that.sortRule.prop === "goTime"
      //         ) {
      //           that.sortChange(that.sortRule);
      //         }
      //         that.dataList = data.data;
      //         that.oldList = data.data;
      //         that.dataListLoading = false;
      //         that.totalPage = data.totalCount;
      //       }
      //     });
      // },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
      },
      handleClick(row) {
        console.log(row);
        this.$router.push("visit/details")
      },
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .clinic {
    position: relative;
    padding-top: 24px;
    .el-form {
      display: flex;
      border-bottom: 1px solid #E9E9E9;
      .el-form-item {
        display: flex;
        margin-right: 48px;
        white-space: nowrap;
        .el-form-item__content {
          width: 240px;
          height: 36px;
          border-radius: 4px;
          border: 1px solid #d9d9d9;
        }
      }
    }
    .button {
      float: left;
      padding: 24px 0;
      .el-button {
        padding: 8px 23px;
        height: 36px;
        background: #0099ff;
        border-radius: 2px;
      }
    }
    .el-table {
      padding-top: 24px;
      position: absolute;
      top: 144px;
    }
    .el-pagination {
      margin-top: 371px;
      .el-pager .number {
        width: 32px;
        height: 32px;
        border-radius: 2px;
        border: 1px solid #d9d9d9;
      }
      .number:hover {
        background-color: #0099ff;
      }
    }
  }
  </style>
  